<template>
  <div v-if="$can('stock_log_list', 'stock_log')">
    <v-card>
      <v-card-title>
        <p class="text-h6">库存管理</p>
      </v-card-title>
      <v-card-text>
        <v-form ref="searchSkuForm" @submit.prevent="toSearch()">
          <v-row>
            <v-col class="d-flex flex-row-reverse pb-0">
              <div class="d-flex align-center">
                <v-btn class="me-3" @click="resetSearch()">
                  <span>重置</span>
                </v-btn>
              </div>
              <div class="d-flex align-center">
                <v-btn color="secondary" class="me-3" type="submit">
                  <v-icon size="18" class="me-1">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  <span>查询</span>
                </v-btn>
              </div>

              <div class="d-flex align-center pt-6 mr-5 pb-0">
                <v-text-field v-model="search.name" outlined dense label="搜索商品"></v-text-field>
              </div>

              <div class="d-flex align-center pt-6 mr-5 pb-0">
                <DateTimePick v-model="search.endTime" label="截止时间" />
              </div>
              <div v-if="$can('stock_log_list_search', 'stock_log')" class="d-flex align-center pt-6 mr-5 pb-0">
                <v-select
                  id="id"
                  v-model="search.categoryId"
                  outlined
                  dense
                  label="所属分类"
                  item-text="name"
                  item-value="id"
                  :items="categorys"
                ></v-select>
              </div>
              <div v-if="$can('stock_log_list_search', 'stock_log')" class="d-flex align-center pt-6 mr-5 pb-0">
                <v-select
                  id="id"
                  v-model="search.warehouseId"
                  outlined
                  dense
                  label="所属仓库"
                  item-text="name"
                  item-value="id"
                  :items="warehouses"
                ></v-select>
              </div>
              <div class="d-flex align-center pt-6 mr-5 pb-0">
                <v-select
                  v-model="search.selectOrderType"
                  :items="orderTypes"
                  label="排序方式"
                  dense
                  outlined
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col>
            <v-data-table
              :footer-props="{
                itemsPerPageAllText: '全部',
                itemsPerPageText: '每页显示 ',
                showCurrentPage: true,
              }"
              disable-filtering
              disable-sort
              :headers="getHeader()"
              :items="skus"
              :page="pages.page"
              :loading="loadings.tableloading"
              loading-text="数据加载中..."
              :items-per-page="pages.itemsPerPage"
              :server-items-length="pages.serverItemsLength"
              no-data-text="无数据"
              @pagination="loadPage"
            >
              <template #[`item.productName`]="{ item }">
                <v-row>
                  <v-col cols="auto">
                    <v-avatar size="33" @click="previewImg(item.skuProductImg)">
                      <img :src="item.skuProductImg || require('@/assets/images/white.jpeg')" alt="John" />
                    </v-avatar>
                  </v-col>
                  <v-col class="pl-0">
                    <span class="text-subtitle-2">{{ item.productName }} </span><br />
                    <span v-if="!item.isSingle" class="text-caption">{{ item.title }}</span>
                  </v-col>
                </v-row>
              </template>
              <template #[`item.categoryName`]="{ item }">
                <v-row>
                  <v-col class="pl-0">
                    <span class="text-subtitle-2">{{ item.categoryName }} </span>
                  </v-col>
                </v-row>
              </template>
              <template #[`item.warehouseName`]="{ item }">
                <v-row>
                  <v-col class="pl-0">
                    <span class="text-subtitle-2">{{ item.warehouseName }} </span>
                  </v-col>
                </v-row>
              </template>
              <template #item.remark="{ item }">
                <v-row>
                  <v-col class="pl-0">
                    <div class="text-container" :title="item.remark">
                      {{ item.remark }}
                    </div>
                  </v-col>
                </v-row>
              </template>
              <template #[`item.showStockNum`]="{ item }">
                <span v-if="showStockNum && item.showStockNum === 0">-</span>
                <span v-else>
                  <span class="text-subtitle-2">{{
                    convertToBoxAndRemainder(item.showStockNum, item.unitConversion[0])
                  }}</span
                  ><br />
                  <span class="text-caption">{{ item.showStockNum }}</span>
                </span>
              </template>
              <template #[`item.lockStockNum`]="{ item }">
                <span v-if="showStockNum && item.lockStockNum === 0">-</span>
                <span v-else>{{ item.lockStockNum }}</span>
              </template>
              <template #[`item.totalStockNum`]="{ item }">
                <span class="text-subtitle-2">{{
                  convertToBoxAndRemainder(item.totalStockNum, item.unitConversion[0])
                }}</span
                ><br />
                <span class="text-caption">{{ item.totalStockNum }}</span>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="$can('stock_log_detail', 'stock_log')"
                  text
                  dense
                  x-small
                  color="primary"
                  @click.stop="showLog(item)"
                >
                  库存日志
                </v-btn>

                <v-btn
                  v-if="$can('product_modify_warehouse', 'stock_log')"
                  text
                  dense
                  x-small
                  color="primary"
                  @click.stop="openUnitDialog(item)"
                >
                  规格
                </v-btn>

                <v-btn
                  v-if="$can('product_modify_warehouse', 'stock_log')"
                  text
                  dense
                  x-small
                  color="primary"
                  @click.stop="openWarehouseDialog(item)"
                >
                  编辑库位
                </v-btn>

                <v-btn
                  v-if="$can('product_modify_remark', 'stock_log')"
                  text
                  dense
                  x-small
                  color="primary"
                  @click.stop="editRemark(item)"
                >
                  备注
                </v-btn>
              </template>
            </v-data-table>
            <div style="position: absolute; right: 260px; bottom: 25px">
              <div style="display: flex; flex-wrap: wrap">
                <span style="padding: 7px; font-size: 13px">总页数：{{ pages.pages }}</span>
                <span style="padding: 7px; font-size: 13px">前往</span>
                <div style="width: 45px">
                  <v-text-field
                    @change="changePage"
                    v-model="toPage"
                    type="number"
                    hide-details
                    dense
                    min="1"
                  ></v-text-field>
                </div>
                <span style="padding: 7px; font-size: 13px">页</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- <v-dialog
      v-model="unitDialog"
      max-width="500"
    >
      <v-card>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="unitDialog" max-width="700">
      <v-card>
        <v-card-title>规格/单位</v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(item, index) in units" :key="index">
              <v-col cols="3">
                <v-text-field v-model="item.name" :disabled="item.isBase" label="规格名称"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="item.unitName" label="单位"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="item.unitQuantity" :disabled="item.isBase" label="单位换算"></v-text-field>
              </v-col>
              <v-col v-if="!item.isBase" cols="2">
                <v-btn color="danger" small style="color: #f4f5fa" @click="deleteUnit(index)"> 删除 </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3" class="mt-3">
                <v-btn @click="addUnit"> 添加规格 </v-btn>
              </v-col>
              <v-col>
                <v-checkbox v-model="isSync" label="是否同步到其他sku商品"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="isSync && skuGroup.length > 0">
                <v-chip v-for="(sku, index) in skuGroup" :key="index" class="ma-2" color="primary">
                  {{ sku.productName }}/{{ sku.title }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveUnit"> 保存 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="previewImgDialog" max-width="500">
      <v-card>
        <v-card-text>
          <v-img :src="previewImgUrl || require('@/assets/images/white.jpeg')" max-width="500"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="remarkDialog" width="500">
      <v-card>
        <v-card-title> 备注 </v-card-title>

        <v-card-text class="mt-5">
          <v-textarea v-model="newRemark" outlined name="input-7-4" label="备注"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleModifyRemark"> 更改 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="warehouseDialog" width="500">
      <v-card>
        <v-card-title> 编辑库位 </v-card-title>

        <v-card-text class="mt-5">
          <v-select
            id="id"
            v-model="newWarehouseId"
            outlined
            dense
            label="所属仓库"
            item-text="name"
            item-value="id"
            :items="warehouses"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleModifyWarehouse"> 更改 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '75%' : '100%'"
      height="100%"
      app
    >
      <v-card v-if="editDialog" height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-h6">库存日志</span>
          &nbsp;&nbsp;
          <v-btn icon small :disabled="prefixSkuId == null" @click="showDetail(prefixSkuId)">
            <v-icon size="22">
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </v-btn>
          &nbsp;&nbsp;
          <v-btn icon small :disabled="suffixSkuId == null" @click="showDetail(suffixSkuId)">
            <v-icon size="22">
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
          <Log :key="skuStock.key" :sku-stock="skuStock" @getNextAndPreSku="getNextAndPreSku" />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeft,
  mdiArrowRight,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import StockLogApi from '@/api/product/stockLog'
import DateTimePick from '@/components/DateTimePicker'
import ProductApi from '@/api/product/product'
import Log from '@/components/Log'

export default {
  name: 'StockLog',
  components: {
    DateTimePick,
    Log,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiArrowLeft,
        mdiArrowRight,
        mdiChevronLeft,
        mdiChevronRight,
      },
      editDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
        tableloading: false,
      },
      stockRecords: [],
      stockTypes: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      baseInfo: {},
      search: {
        selectOrderType: 0,
      },
      warehouses: [],
      categorys: [],
      skus: [],
      skuStock: {
        key: 0,
      },
      showStockNum: false,
      prefixSkuId: null,
      suffixSkuId: null,
      showLogTime: true,
      warehouseDialog: false,
      newWarehouseId: null,
      modifySkuId: null,
      remarkDialog: false,
      newRemark: '',
      previewImgUrl: '',
      previewImgDialog: false,
      unitDialog: false,
      units: [],
      isSync: false,
      skuGroup: [],
      toPage: null,
      orderTypes: [
        {
          text: '按商品分类排序',
          value: 0,
        },
        {
          text: '按库存数量升序',
          value: 1,
        },
        {
          text: '按库存数量降序',
          value: 2,
        },
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.$can('stock_log_list_search', 'stock_log')) {
        StockLogApi.loadData().then(res => {
          const { categorys, warehouses } = res.data.data
          categorys.forEach(category => {
            if (category.parentId !== 0) {
              category.name = `\xa0\xa0${category.name}`
            }
          })
          this.categorys = categorys
          this.warehouses = warehouses
        })
      }
    },
    showLog(item) {
      this.skuStock.skuId = item.skuId
      this.skuStock.categoryId = this.search.categoryId
      this.skuStock.warehouseId = this.search.warehouseId
      this.skuStock.name = this.search.name
      this.editDialog = true
    },
    getHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'skuId' },
        { text: '商品名称', value: 'productName' },
        { text: '所属分类', value: 'categoryName' },
        { text: '所属仓库', value: 'warehouseName' },
        { text: '显示库存', value: 'showStockNum' },
        { text: '锁定库存', value: 'lockStockNum' },
        { text: '全部库存', value: 'totalStockNum' },
        { text: '备注', value: 'remark' },
      ]
      if (
        this.$can('stock_log_detail', 'stock_log') ||
        this.$can('product_modify_remark', 'stock_log') ||
        this.$can('product_modify_warehouse', 'stock_log')
      ) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.toPage = null
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    resetSearch() {
      this.search.endTime = ''
      this.toPage = null
      this.$refs.searchSkuForm.reset()
      this.$nextTick(() => {
        this.search.selectOrderType = 0
      })

      this.toSearch()
    },
    changePage(modifyPage) {
      if (modifyPage > this.pages.pages) {
        this.$toast.error('超过最大总页数')
        return
      }
      this.pages.page = parseInt(modifyPage)
    },
    loadPage({ page, itemsPerPage }) {
      this.loadings.tableloading = true
      let rows = itemsPerPage
      if (!rows) {
        rows = this.pages.itemsPerPage
      }
      if (this.search.endTime) {
        this.showStockNum = true
      } else {
        this.showStockNum = false
      }
      if (this.$route.query.warehouseId) {
        this.search.warehouseId = parseInt(this.$route.query.warehouseId, 0)
        this.$route.query.warehouseId = null
      }
      const searchData = {
        rows,
        page,
        categoryId: this.search.categoryId,
        warehouseId: this.search.warehouseId,
        name: this.search.name,
        endTime: this.search.endTime,
        orderType: this.search.selectOrderType || 0,
      }
      if (this.search.saleType) {
        const type = this.search.saleType
        if (type === 'OUTSIDE_SALE') {
          searchData.isOutsideSale = true
        } else {
          searchData.isInsideSale = true
        }
      }
      StockLogApi.getPages(searchData)
        .then(response => {
          this.loadings.tableloading = false
          const { data } = response.data

          this.skus = data.records
          this.pages.page = data.current
          this.pages.pages = data.pages
          if (data.size === -1) {
            this.pages.serverItemsLength = this.skus.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
        .catch(error => {
          this.loadings.tableloading = false
        })
    },
    showDetail(skuId) {
      this.skuStock.skuId = skuId
      this.skuStock.categoryId = this.search.categoryId
      this.skuStock.warehouseId = this.search.warehouseId
      this.skuStock.name = this.search.name
      this.skuStock.key += 1
    },
    getNextAndPreSku(item) {
      this.prefixSkuId = item.prefixSkuId
      this.suffixSkuId = item.suffixSkuId
    },
    openWarehouseDialog(item) {
      this.newWarehouseId = item.warehouseId
      this.modifySkuId = item.skuId
      this.warehouseDialog = true
    },
    handleModifyWarehouse() {
      if (!this.newWarehouseId) {
        this.$toast.error('请选择仓库')

        return
      }

      ProductApi.modifySkuWarehouse(this.modifySkuId, this.newWarehouseId).then(res => {
        this.$toast.success('修改成功')
        this.loadPage(this.pages)
        this.warehouseDialog = false
      })
    },
    editRemark(item) {
      this.modifySkuId = item.skuId
      this.newRemark = item.remark
      this.remarkDialog = true
    },
    handleModifyRemark() {
      ProductApi.modifySkuRemark(this.modifySkuId, this.newRemark).then(res => {
        this.$toast.success('修改成功')
        this.loadPage(this.pages)
        this.remarkDialog = false
      })
    },
    previewImg(url) {
      this.previewImgUrl = url
      this.previewImgDialog = true
    },
    openUnitDialog(item) {
      this.isSync = false
      this.units = item.unitConversion
      this.modifySkuId = item.skuId
      ProductApi.getSkuGroupBySkuId(item.skuId).then(res => {
        this.skuGroup = res.data.data
        this.unitDialog = true
      })
    },
    addUnit() {
      this.units.push({
        id: '',
        name: '',
        unitName: '',
        unitQuantity: 1,
        isBase: false,
      })
    },
    deleteUnit(index) {
      this.units.splice(index, 1)
    },
    saveUnit() {
      let check = false
      for (let index = 0; index < this.units.length; index++) {
        const unit = this.units[index]

        // 校验如果unit中的name、unitName、unitQuantity有一个为空，则不允许保存
        if (!unit.name || !unit.unitName || !unit.unitQuantity) {
          check = true
          break
        }
      }

      if (check) {
        this.$toast.warning('请完善规格/单位信息')
      }

      const saveData = {
        skuId: this.modifySkuId,
        unitConversion: this.units,
        isSync: this.isSync,
      }

      ProductApi.modifySkuUnit(saveData).then(res => {
        this.$toast.success('修改成功')
        this.loadPage(this.pages)
        this.unitDialog = false
      })
    },
    convertToBoxAndRemainder(totalQuantity, selectUnit) {
      const { unitName } = selectUnit
      const { unitQuantity } = selectUnit

      // 如果isBase为true，则直接返回基准单位的数量
      if (selectUnit.isBase) {
        return `${totalQuantity}${unitName}`
      }

      // 计算整箱数和尾数
      const boxes = Math.floor(totalQuantity / unitQuantity)
      const remainder = totalQuantity % unitQuantity

      // 构建结果字符串
      let result = ''

      // 如果没有整箱，则只显示尾数
      if (boxes === 0) {
        result = `0箱+${remainder}个尾数`
      } else {
        // 否则显示箱数和尾数
        result = `${boxes}箱`

        // 如果有尾数，则添加尾数部分
        if (remainder > 0) {
          result += `+${remainder}个尾数`
        }
      }

      return result
    },
  },
}
</script>

<style>
.text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* Adjust this value to fit your table column width */
  position: relative;
  cursor: pointer;
}

.text-container:hover::after {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  white-space: normal;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
</style>
